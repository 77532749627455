import { createRouter, createWebHistory } from 'vue-router'
import Home from './pages/Home.vue'
import AIS from './pages/AIS.vue'
import PIS from './pages/PIS.vue'
import Terms from './pages/Terms.vue'
import Privacy from './pages/Privacy.vue'

const pageTitle = process.env.VUE_APP_TITLE || 'Enable Banking'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: pageTitle,
        description: ''
      }
    },
    {
      path: '/ais/:pathMatch(.*)*',
      name: 'AIS',
      component: AIS,
      meta: {
        title: pageTitle,
        description: ''
      }
    },
    {
      path: '/pis/:pathMatch(.*)*',
      name: 'PIS',
      component: PIS,
      meta: {
        title: pageTitle,
        description: ''
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      meta: {
        title: 'Terms of Enable Banking API Service',
        description: ''
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
      meta: {
        title: 'Privacy policy of Enable Banking API Service',
        description: ''
      }
    }
  ]
})

export default router
